import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_READERS } from "./queries";
import { CatItem, Language, Level, ReaderImages } from "../../../models/reader";
import TitleBlock from "./title_block";
import { Helmet } from "react-helmet";
import { BuyNowButton } from "../homepage/hero";
import { IconJumbotron } from "../../common/icons";

export type BooksTitlesType = {
  coming_soon: boolean;
  title: string;
  author?: string;
  description_short: string;
  themes?: string;
  level_id: string;
  image_ver: number;
  images: ReaderImages;
  level: Level;
  language: Language;
  categories: [CatItem];
};

const TitlesPage: React.FC = () => {
  const [books, setBooks] = useState<BooksTitlesType[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: booksDB } = useQuery<{ booksTitles: BooksTitlesType[] }>(
    GET_READERS
  );
  const [langs, setLangs] = useState<Language[]>([]);

  useEffect(() => {
    if (booksDB) {
      const set_langs: any = {};
      booksDB.booksTitles.forEach((b) => {
        if (!set_langs[b.language.id]) {
          set_langs[b.language.id] = b.language;
        }
      });
      setLangs(Object.values(set_langs));
      setBooks(booksDB.booksTitles);
    }
  }, [booksDB]);

  useEffect(() => {
    if (searchTerm !== "") {
      filterBooks(searchTerm);
    } else {
      setBooks(booksDB?.booksTitles || []);
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  const filterBooks = (search_termn: string) => {
    if (booksDB === undefined || search_termn.length < 3) {
      return;
    }
    const filtered = booksDB.booksTitles.filter(
      (book) =>
        book.title.toLowerCase().includes(search_termn.toLowerCase()) ||
        book.author?.toLowerCase().includes(search_termn.toLowerCase()) ||
        book.themes?.toLowerCase().includes(search_termn.toLowerCase())
    );

    setBooks(filtered);
  };

  return (
    <div className="titles_page front_face">
      <Helmet title="A Library of Spanish, French, and German Readers!" />
      <div
        className="hero"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_CDN_IMG}flangoo-bg-books-image.png?v=1')`,
        }}
      ></div>
      <div className="container titles">
        <h1>
          A Library of Spanish, French, and German Readers, with More on the
          Way!
        </h1>
        <p>
          Best-selling readers from authors Mira Canion, Jennifer Degenhardt,
          Deb Navarre, Tom Alsop, Fabiola Canale, and many more. Flangoo also
          features Acquisition™ Readers with comprehension questions, and
          Enhanced™ Readers featuring audio, video, cultural notes, and
          comprehension questions.
        </p>

        <InputGroup className="mb-3 w-25">
          <InputGroup.Text
            id="basic-addon1"
            style={{ borderRadius: "5px 0 0 5px" }}
          >
            <i className="fas fa-search"></i>{" "}
          </InputGroup.Text>
          <Form.Control
            placeholder="Search for a title or author..."
            aria-label="Search for a title or author..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-describedby="search by book title"
          />
        </InputGroup>

        {langs.map((lang) => (
          <TitlesRow
            key={`title_Row${lang.id}`}
            lang={lang}
            titles={books.filter((b) => b.language.id === lang.id)}
          />
        ))}
      </div>
      <BuyNowButton main_cta={"Try It Free"} />
    </div>
  );
};

type TitlesRowProps = {
  lang: Language;
  titles: BooksTitlesType[];
};

const TitlesRow: React.FC<TitlesRowProps> = ({ lang, titles }) => (
  <div className="books_container">
    <h2>{lang.name} Readers</h2>
    <div className="books">
      {titles.length > 0 ? (
        titles.map((t) => (
          <TitleBlock key={`title_item${t.title}`} bookTitle={t} />
        ))
      ) : (
        <IconJumbotron
          icon="fas fa-book-open"
          classes="text-muted opacity_4"
          txt="No titles found"
          txt_classes="text-dark"
          help_txt_classes="text-dark"
          help_text="We are adding more readers to our library. Check back soon for more titles!"
        />
      )}
    </div>
  </div>
);

export default TitlesPage;
