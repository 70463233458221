import { gql } from "@apollo/client";

export const GET_READERS = gql`
  query getBooks {
    booksTitles {
      title
      coming_soon
      image_ver
      author
      description_short
      themes
      images {
        cover
      }
      language {
        id
        name
      }
    }
  }
`;
