import React from "react";
import { useQuery } from "@apollo/client";

import { Helmet } from "react-helmet";
import { PageSettingItem } from "../../../models/pageSettings";
import { PlanType } from "../../../models/plans";
import { GET_PLANS } from "../purchaseplan/queries";
import Plans from "./plans";
import { LoaderDots } from "../../../middleware/main_loader";

const PricingPage: React.FC = () => {
  const { data } = useQuery<{
    plans: PlanType[];
    pageSettings: PageSettingItem[];
  }>(GET_PLANS, { fetchPolicy: "no-cache" });
  return (
    <div className="pricing_page">
      <Helmet title="Pricing Page" />

      <div className="pricing_page front_face">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-12">
              <h1>Purchase any of the membership packages from below:</h1>
              {data?.plans ? (
                <Plans plans={data.plans} isBuyUrl={true} />
              ) : (
                <div className="d-flex w-100 justify-content-center py-10">
                  <LoaderDots size={20} />
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h2>Flangoo Gives You Instant Access to:</h2>

              <ul style={{ fontSize: "16px", color: "#fff" }}>
                <li>
                  <b>Spanish, French, and German readers</b> with more being
                  added all the time
                </li>
                <li>
                  <b>Native-spoken audio</b> for every title
                </li>
                <li>
                  <b>Mouse-over glossary</b> for the tough words in Levels 1 and
                  2
                </li>
                <li>
                  <b>Various levels</b> to meet your students'
                  needs&mdash;Levels Pre-1, 1, 2, and 3+
                </li>
                <li>
                  <b>Best-selling authors</b> Blaine Ray, Mira Canion, Jennifer
                  Degenhardt, Fabiola Canale, Deb Navarre, Tom Alsop, and many
                  more
                </li>
                <li>
                  <b>Comprehension questions</b> included for every chapter of
                  every book
                </li>
                <li>
                  <b>Enhanced™ Readers</b> featuring video, audio, cultural
                  notes, and comprehension questions
                </li>
                <li>
                  <b>Engaging titles</b> and features being added every month!
                </li>
                <li>
                  <b>One account</b> supports up to 180 of your students at no
                  additional cost!
                </li>
                <li>
                  <b>New reporting features</b> give you detailed information
                  about your students' reading time
                </li>
              </ul>

              <h2>It Starts with a Free Trial.</h2>

              <p>
                There's no credit card required and no strings attached. We just
                want you to try it for yourself, because we feel confident that
                once you try it, you and your students are going to love it.
              </p>
              <p>
                When you're ready to purchase Flangoo, simply click “Upgrade
                Membership” in your Flangoo account settings, or you can
                purchase your subscription through Teacher's Discovery. Our
                customer support team is ready to assist you, and you can cancel
                your subscription at any time.
              </p>

              <div className="ctas">
                <a
                  href="https://www.teachersdiscovery.com/product/flangoo-spanish-digital-readers-subscription/spanish"
                  target="_blank"
                  rel="noreferrer"
                  className="main_cta btn btn-danger btn-lg buy_btn"
                >
                  Buy Flangoo Now at Teacher's Discovery
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
