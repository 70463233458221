import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { SENDHELP } from "../help/queries";
import HelpForm, { FormType } from "../help/helpForm";
import { Helmet } from "react-helmet";

const LMSHelpPage: React.FC = () => {
  const [form, setForm] = useState<FormType>({
    sended: false,
    type: "lms_help",
    aditionalData: {},
  });
  const [sendHelp, { data: response, loading }] = useMutation(SENDHELP);

  useEffect(() => {
    const document_body = document.getElementsByTagName("body")[0];
    if (document_body) {
      document_body.classList.add("white_bg");
    }
    return () => {
      document_body.classList.remove("white_bg");
    };
  }, []);

  const handleChange = (ev: {
    target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
  }) => {
    const value = ev.target.value;
    const type = ev.target.dataset.type;
    const currentForm = { ...form };
    ///search if string has "aditionalData" and if it has, split it and set the value
    if (type?.includes("aditionalData")) {
      const subkey = type.split(".").pop();
      // @ts-ignore next-line
      currentForm.aditionalData[subkey] = value;
      setForm(currentForm);
      return;
    } else {
      // @ts-ignore next-line
      currentForm[type] = value;
    }
    setForm(currentForm);
  };

  const handleSubmit = () => {
    if (!form.type || !form.message) return;
    let message = form.message;
    if (form.aditionalData) {
      message += `\n\nTeachers: ${form.aditionalData.teachers}\nStudents: ${form.aditionalData.students}`;
    }
    sendHelp({
      variables: {
        topic: form.type,
        message,
        email: form.email,
        name: form.name,
      },
    });
  };

  return (
    <div className="container support_page front_face first-container">
      <Helmet>
        <title>LMS Help</title>
      </Helmet>
      <div className="row">
        <div className="col-md-8">
          <h1 className="black_text mb-5">Want your LMS connected?</h1>
          {/* <h4>To Link with Google Classroom</h4>
          <p>
            <ol>
              <li>Click Log In or Sign Up</li>
              <li>Click the "Log in with your LMS" button.</li>
              <li>Select your Google account.</li>
              <li>Review the permissions and press the Allow button.</li>
              <li>
                If this is your first time logging in with Google Classroom you
                will now be prompted to enter your Class ID and User ID.
              </li>
              <li>Your account is now linked!</li>
            </ol>
          </p>

          <h4>To Link with Canvas or Schoology</h4>
          <p>
            <ol>
              <li>
                Before you can proceed, your school's LMS administrator must
                complete a quick configuration. It should take less than 5
                minutes. Please send your administrators the following link. If
                they have any questions, please ask them to email{" "}
                <CopyButton
                  text={"flangoo@teachersdiscovery.com"}
                  classes="text-primary"
                >
                  flangoo@teachersdiscovery.com
                </CopyButton>{" "}
                or fill the form at the bottom of this page
                <br />
                <CopyButton
                  text={
                    "https://ed.link/integrate/9aabc0db-fdeb-43e7-aadd-028467a96ac9"
                  }
                  classes="text-primary"
                >
                  https://ed.link/integrate/9aabc0db-fdeb-43e7-aadd-028467a96ac9
                </CopyButton>
              </li>
              <li>
                Once that configuration is complete you can access Flangoo using
                your LMS. To start, click Log In
              </li>
              <li>
                On the Log in or Sign up page, click the "Log in with your LMS"
                button.
              </li>
              <li>Select your LMS account.</li>
              <li>
                If this is your first time logging in with your LMS it will
                prompt you to authorize the connection.
              </li>
              <li>
                If this is your first time logging in with your LMS you will now
                be prompted to enter your Class ID and User ID.
              </li>
              <li>Your account is now linked!</li>
            </ol>
          </p> */}
          <div className="my-4">
            {response ? (
              <div
                className="alert alert-dismissible alert-success"
                style={{ margin: "30px" }}
              >
                Message sent!
              </div>
            ) : (
              <HelpForm
                form={form}
                title="Request SSO Integration"
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                sent={form.sended}
                loading={loading}
              />
            )}
          </div>

          <p>
            Technically, you can always mail us, too. <br />
            Teacher's Discovery® / Flangoo.com <br />
            2741 Paldan Drive, Auburn Hills, Michigan 48326
          </p>
        </div>
      </div>
    </div>
  );
};

export default LMSHelpPage;
