import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PageSettingItem } from "../../../models/pageSettings";
import { IconJumbotron } from "../../common/icons";

import { PlanType } from "../../../models/plans";
import { ButtonWrapper } from "../purchaseplan/payment";

type PaymentProps = {
  selectedPlan: PlanType;
  paypal_setting?: PageSettingItem;
};

const currency = "USD";

const PaymentSection: React.FC<PaymentProps> = ({
  selectedPlan,
  paypal_setting,
}) => {
  const paypal_settings = JSON.parse(
    paypal_setting?.description || "[]"
  ) as PaypalSettingsType[];
  const paypal = paypal_settings[0];

  return (
    <div className="payment_section">
      {paypal_setting ? (
        <PayPalScriptProvider
          options={{
            "client-id":
              paypal.mode === "sandbox"
                ? paypal.sandbox_client_id
                : paypal.production_client_id,
            components: "buttons",
            currency,
          }}
        >
          <ButtonWrapper
            currency={currency}
            showSpinner={false}
            selectedPlan={selectedPlan}
            isBuyNow={true}
            price_amount={selectedPlan.price_amount}
          />
        </PayPalScriptProvider>
      ) : (
        <IconJumbotron
          icon="fa-solid fa-bug-slash"
          txt="Something went wrong"
          help_text="please refresh and try again"
        />
      )}
    </div>
  );
};

export default PaymentSection;
