import { useState } from "react";

export type SortBy = {
  by: string;
  sort?: "desc" | "asc";
};

export type Options = {
  by: string;
  sort?: "desc" | "asc";
};

export function sortArray<T>(
  data: T[],
  sort_by: string,
  sort: "desc" | "asc" = "asc"
): T[] {
  if (!Array.isArray(data)) {
    throw new Error("Data must be an array.");
  }
  if (typeof sort_by !== "string") {
    throw new Error("Sort_by must be a string.");
  }

  const levels = sort_by.split(".");
  const getNestedValue = (item: any, levels: string[]): any => {
    return levels.reduce((value, level) => value?.[level], item);
  };

  const compareValues = (a: any, b: any): number => {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b);
    } else if (typeof a === "number" && typeof b === "number") {
      return a - b;
    }
    return 0;
  };

  return data.sort((a, b) => {
    const aValue = getNestedValue(a, levels);
    const bValue = getNestedValue(b, levels);

    if (bValue === undefined) return -1;
    if (aValue === undefined) return 1;

    const comparisonResult = compareValues(aValue, bValue);
    return sort === "asc" ? comparisonResult : -comparisonResult;
  });
}

function useSort(options: Options): [SortBy, any] {
  const [sort_by, setSortBy] = useState<SortBy>({
    by: options.by,
    sort: options.sort,
  });
  const handleSort = (sort_by: SortBy) => {
    setSortBy({
      by: sort_by.by,
      sort: sort_by.sort,
    });
  };

  return [sort_by, handleSort];
}

export default useSort;
