import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, ButtonGroup } from "reactstrap";

import { SETLANG } from "../../layouts/queries";
import { LangItem } from "../../../models/lang";
import { UserItem } from "../../../models/user";

type LanguagesProps = {
  languages: LangItem[];
  activeOnly?: boolean;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Languages: React.FC<LanguagesProps> = ({
  languages,
  activeOnly,
  setReload,
  setLoading,
}) => {
  const user: UserItem = JSON.parse(localStorage.getItem("user") || "{}");
  const [user_active, setuser_active] = useState(user.lang_id);
  const [setLang] = useMutation(SETLANG);
  const display_langs = languages.map((l) => l);
  const handleChangeLang = async (ev: { currentTarget: HTMLButtonElement }) => {
    const { lang_id } = ev.currentTarget.dataset;
    setLoading!(true);
    if (!lang_id) return false;
    const set_user = { ...user };
    set_user.lang_id = lang_id;
    setuser_active(lang_id);
    localStorage.setItem("user", JSON.stringify(set_user));
    await setLang({ variables: { lang_id } });
    setLoading!(false);
    setReload!(true);
  };

  ///return null if display_langs is empty
  if (display_langs.length === 0) return null;

  return (
    <>
      <p className={activeOnly ? "mb-0" : ""}>
        {activeOnly ? "Your languages" : "Other Flangoo languages"}:{" "}
      </p>
      {activeOnly ? (
        <small className="help mb-2 d-block">
          Select the language you want to browse
        </small>
      ) : null}
      <ButtonGroup>
        {languages && languages.length > 0
          ? display_langs.map((lang) => (
              <Button
                color={lang.id === user_active ? "primary" : "outline-light "}
                className="no_focus"
                active={lang.id === user_active}
                key={`Button_${lang.id}`}
                data-lang_id={lang.id}
                onClick={handleChangeLang}
                data-active={lang.active ? "true" : "false"}
              >
                <i
                  className={`fal mr-2 ${
                    lang.id === user_active ? "fa-check-square" : "fa-square"
                  }`}
                ></i>

                {lang.name}
              </Button>
            ))
          : null}
      </ButtonGroup>
    </>
  );
};
