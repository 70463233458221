import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { UserSettings } from "../../../models/user";
import { DataContext } from "../../../context/data-context";
import Appearance from "../settings/appearance";
import { SETPREFF } from "../profile/queries";
import { Languages } from "../settings/components";
import { LangItem } from "../../../models/lang";
import { LoaderPuff } from "../../../middleware/loaders";

type StudentSettingsProps = {
  settings: UserSettings;
  setSettings: React.Dispatch<React.SetStateAction<UserSettings>>;
};
const StudentSettings: React.FC<StudentSettingsProps> = ({
  settings,
  setSettings,
}) => {
  const dataContext = useContext(DataContext);
  const [setPreff] = useMutation(SETPREFF);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const langs: LangItem[] = JSON.parse(
    localStorage.getItem("local_languages") || "{}"
  );

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
    };
  }, [reload]);

  const handleUpdateSetting = (setting: keyof UserSettings, value: any) => {
    const set_settings = { ...settings, [setting]: value };
    setSettings(set_settings);
    ///run mutation to update settings
    setPreff({
      variables: {
        pref_value: value.toString(),
        pref_key: setting,
        is_global: false,
      },
    });
    dataContext.setSettings(set_settings);
  };

  return (
    <div>
      {loading ? <LoaderPuff fixed /> : null}
      <hr />
      <Appearance setSettings={handleUpdateSetting} />
      <hr />
      <Languages
        languages={langs}
        activeOnly
        setReload={setReload}
        setLoading={setLoading}
      />
      <br />
      <small className="help">Select the language you want to browse</small>
    </div>
  );
};

export default StudentSettings;
