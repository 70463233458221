import { FilterFun } from "../models/filters";
import { CatItem } from "../models/reader";

export function isNumber(num: string | number | boolean): boolean {
  if (num === false || num === true) return false;
  const reg = /^\d+$/;
  if (typeof num == "number") return true;
  return reg.test(num);
}

export function validateEmail(email: string): boolean {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const response = re.test(String(email).toLowerCase());
  return response;
}

export function validatePassword(password: string): boolean {
  if (password.length < 6) return false;
  return true;
}

export function smartRedirect(page: string): string | false {
  const no_redirect = [
    "bookdetails",
    "editprofile",
    "reader",
    "student",
    "students",
    "books",
    "new_assignment",
    "assignment",
    "general",
    "rate",
    "library",
    "grades",
    "settings",
  ];
  if (no_redirect.indexOf(page) >= 0) {
    return false;
  }
  switch (page) {
    case "home":
      return `/`;
    case "spanish":
      return `/library/spanish`;
    case "french":
      return `/library/french`;
    case "german":
      return `/library/german`;

    default:
      return `/${page}`;
  }
}

export function useQueryParams(url: Location) {
  return new URLSearchParams(url.search);
}

export function getUserImage(url: string): string {
  const assets_url = process.env.REACT_APP_CDN_URL;

  return `${assets_url}/${url}`;
}

export const filterOptions: FilterFun = (options) => {
  return (query) => {
    const normalizedQuery = query
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, "");

    const response = options.filter((item) => {
      const normalizedName = item.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, "");

      return normalizedName.includes(normalizedQuery);
    });

    return response;
  };
};

export function renderBuyText(categories: CatItem[]) {
  let buy_text = "Need a Class Set of Paperbacks?";
  categories.forEach((cat) => {
    if (cat.buy_text) {
      buy_text = cat.buy_text;
    }
  });
  return buy_text;
}

export function mins_to_hours(mins: number) {
  if (mins < 1) {
    return "0:00";
  }
  const num = mins;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}h:${rminutes}m`;
}

export function formatNumber(num: number): string {
  const response = num.toFixed(0);
  return response.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatPrice(price_amount: number) {
  let whole = Math.floor(price_amount);
  let decimal = (price_amount % 1).toFixed(2).substr(2);

  if (decimal === "00") {
    decimal = "00";
  }

  return { whole, decimal };
}

export function formatPlanName(plan: string) {
  if (!plan) return "";

  return plan.replace(/(\d+) Year(s?)/, "$1-Year");
}

export function detectDevice() {
  const document_body = document.getElementById("main_wrapper");
  if (!document_body) {
    console.log("common-functions.ts:134 | detectDevice");
    setTimeout(() => {
      detectDevice();
    }, 2000);
    return;
  }

  // For Windows
  if (navigator.platform.indexOf("Win") > -1) {
    document_body.classList.add("is-windows-device");
  }

  // For iPhone
  else if (navigator.platform === "iPhone") {
    document_body.classList.add("is-iphone-device");
  }

  // For Mac
  else if (navigator.platform.indexOf("Mac") > -1) {
    document_body.classList.add("is-mac-device");
  }
}

// Call the function
detectDevice();
