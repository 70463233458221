import React, { MouseEvent } from "react";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { PlanType } from "../../../models/plans";
import {
  formatPrice,
  formatPlanName,
} from "../../../middleware/common-functions";
import { PlanItem } from "../../../models/user";

type PlansProps = {
  plans: PlanType[];
  selectedPlan?: PlanType;
  handlePlanSelect?: (ev: MouseEvent<HTMLButtonElement>) => void;
  isBuyUrl?: boolean;
};

const Plans: React.FC<PlansProps> = ({
  plans,
  selectedPlan,
  handlePlanSelect,
  isBuyUrl,
}) => {
  const history = useHistory();
  const plans_data: PlanItem[] = JSON.parse(
    localStorage.getItem("plans_data") || "{}"
  );
  const pupularPlanId = "3";
  const free_trial = plans_data.find((plan) => plan.id === "2");
  const navigateToPayment = (plan_id: string) => {
    history.push("/payment/" + plan_id);
  };
  if (isMobile) {
    return (
      <div className="pricing_table">
        {plans.length > 0
          ? plans.map((plan) => {
              const formatedPrice = formatPrice(plan.price_amount);

              return (
                <div className="" key={`plan.name.card${plan.id}`}>
                  {plan.id === pupularPlanId ? (
                    <span className="popular_span">Most Popular </span>
                  ) : null}
                  <div
                    className={`card ${
                      plan.id === pupularPlanId ? "popular" : ""
                    }`}
                  >
                    <div
                      className="card-body"
                      onClick={() => {
                        navigateToPayment(plan.id);
                      }}
                    >
                      <h5>{formatPlanName(plan.name)} Plan</h5>
                      <h3>
                        USD ${formatedPrice.whole}.{""}
                        <sup>{formatedPrice.decimal}</sup>
                      </h3>

                      <Link
                        to={`/payment/${plan.id}`}
                        className={`buy_now_btn btn btn-${
                          plan.id === pupularPlanId ? "" : "outline-"
                        }danger btn-block py-3`}
                      >
                        {" "}
                        Buy Now
                      </Link>
                      <small className="text-muted text-center d-block">
                        <i className="text-success fas fa-badge-dollar"></i>{" "}
                        {free_trial?.days || "14"}-day money-back guarantee{" "}
                        <br /> Does not auto-renew
                      </small>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <i
                          className="text-success fa fa-check"
                          aria-hidden="true"
                        ></i>{" "}
                        Students Access: {plan.students} students
                      </li>
                      <li className="list-group-item">
                        {" "}
                        <i
                          className="text-success fa fa-check"
                          aria-hidden="true"
                        ></i>{" "}
                        Access on Your Computer, TV, <br /> Phone, and Tablet
                      </li>
                      <li className="list-group-item">
                        <i
                          className="text-success fa fa-check"
                          aria-hidden="true"
                        ></i>{" "}
                        Spanish, French, and German Titles
                      </li>
                      <li className="list-group-item">
                        <i
                          className="text-success fa fa-check"
                          aria-hidden="true"
                        ></i>{" "}
                        Cancel Anytime
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  }
  return (
    <div className={`pricing_table ${isBuyUrl ? "col-lg-12" : "col-lg-10"}`}>
      <h4 className="black_text">
        {handlePlanSelect
          ? "Purchase any of the membership packages below."
          : "Flangoo Plans"}
      </h4>
      {handlePlanSelect ? (
        <ul className="black_text">
          <li>Select your preferred membership package and make payment.</li>
          <li>You can cancel your subscription at any time.</li>
          <li>
            You can also subscribe using a purchase order on the Teacher's
            Discovery website by{" "}
            <a
              href="https://www.teachersdiscovery.com/product/29270"
              target="_blank"
              rel="noreferrer"
            >
              <u>clicking here</u>
            </a>
            .
          </li>
          {isBuyUrl ? (
            ""
          ) : (
            <li>
              Renewing will ADD on to the end of your current expiration date.
            </li>
          )}
        </ul>
      ) : null}
      <table className="table table-striped table-hover">
        <thead>
          <tr role="rowheader">
            <th className="empty"></th>
            {plans.length > 0
              ? plans.map((plan) => (
                  <th
                    className={`${
                      plan.id === pupularPlanId ? "popular_th" : "empty"
                    }`}
                    key={`rowheader${plan.id}`}
                  >
                    {plan.id === pupularPlanId ? "Most popular" : ""}
                  </th>
                ))
              : null}
          </tr>
          <tr role="rowheader">
            <th className="empty"></th>
            {plans.length > 0
              ? plans.map((plan) => {
                  const formatedPrice = formatPrice(plan.price_amount);
                  return (
                    <th
                      key={`plan.name${plan.id}`}
                      className={`text-center ${
                        plan.id === pupularPlanId ? "popular" : ""
                      }`}
                    >
                      <h5>{formatPlanName(plan.name)} Plan</h5>
                      <h3>
                        USD <br />${formatedPrice.whole}.{""}
                        <sup>{formatedPrice.decimal}</sup>
                      </h3>
                      {plan.regular_price ? (
                        <span className="regular_price text-danger">
                          <del>${plan.regular_price}</del>
                        </span>
                      ) : (
                        <span className="regular_price opacity_0">empty</span>
                      )}

                      <Link
                        to={`/payment/${plan.id}`}
                        className={`btn btn-${
                          plan.id === pupularPlanId ? "" : "outline-"
                        }danger btn-block mt-2 py-3`}
                      >
                        {" "}
                        Buy Now
                      </Link>
                      <small className="text-muted">
                        <i className="text-success fas fa-badge-dollar"></i>{" "}
                        {free_trial?.days || "14"}-day money-back guarantee
                      </small>
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number of Students That Can Access As Well</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    key={`plan.students${plan.id}`}
                    className={`text-center ${
                      plan.id === pupularPlanId ? "popular" : ""
                    }`}
                  >
                    {plan.students}
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>
              Access on Your Computer, TV, <br /> Phone, and Tablet
            </td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    className={`text-center ${
                      plan.id === pupularPlanId ? "popular" : ""
                    }`}
                    key={`access_on${plan.id}`}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Spanish, French, and German Titles</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    className={`text-center ${
                      plan.id === pupularPlanId ? "popular" : ""
                    }`}
                    key={`languages${plan.id}`}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Cancel Anytime</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    className={`text-center ${
                      plan.id === pupularPlanId ? "popular popular_last" : ""
                    }`}
                    key={`cancel${plan.id}`}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          {handlePlanSelect ? (
            <tr>
              <td>
                <span>Select Your Plan:</span>
              </td>
              {plans.length > 0
                ? plans.map((plan) => (
                    <td
                      className={`text-center ${
                        plan.id === pupularPlanId ? "popular" : ""
                      }`}
                      key={`button${plan.id}`}
                    >
                      <button
                        className={`btn  btn-block py-3 ${
                          selectedPlan && selectedPlan.id === plan.id
                            ? "btn-success"
                            : "btn-outline-dark"
                        }`}
                        onClick={handlePlanSelect}
                        data-plan-id={plan.id}
                      >
                        {selectedPlan && selectedPlan.id === plan.id
                          ? "Selected"
                          : "Select"}
                      </button>
                    </td>
                  ))
                : null}
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default Plans;
