import React from "react";

type WalkthroughProps = {
  onpenCalendly: () => void;
  saveAchievement: (id: string) => void;
};

const Walkthrough: React.FC<WalkthroughProps> = ({
  onpenCalendly,
  saveAchievement,
}) => {
  return (
    <div className="starter_steps mb-3 " id="starter_steps">
      <div className="container first-container">
        <div className="text-center hero-txt">
          <h1>
            <i className="fal fa-gift"></i>
          </h1>
          <h2>Schedule a walkthrough</h2>
          <h4>and get 30 more days!</h4>
          {/*  Calendly link widget begin */}
          <button
            className=" main_cta btn btn-danger btn-lg"
            onClick={() => {
              onpenCalendly();
              saveAchievement("15");
            }}
          >
            <i className="fal fa-calendar mr-2" aria-hidden="true"></i>
            Schedule a 15-Minute Tour
          </button>
        </div>
      </div>
    </div>
  );
};

export default Walkthrough;
