import React from "react";
import { PlanType } from "../../../models/plans";
import { PlanItem } from "../../../models/user";
import { formatPlanName } from "../../../middleware/common-functions";

type OrderSummaryProps = {
  selectedPlan: undefined | PlanType;
};
const OrderSummary: React.FC<OrderSummaryProps> = ({ selectedPlan }) => {
  const plans_data: PlanItem[] = JSON.parse(
    localStorage.getItem("plans_data") || "{}"
  );
  const free_trial = plans_data.find((plan) => plan.id === "2");
  if (!selectedPlan) {
    return null;
  }
  return (
    <div className="order_summary">
      <p className="black_text">Order Summary</p>
      <div className="row">
        <div className="col">
          <h5>{formatPlanName(selectedPlan.name)} Plan</h5>
        </div>
        <div className="col text-right">
          <h3>${selectedPlan.price_amount}</h3>
          {selectedPlan.regular_price ? (
            <p className="text-danger">
              <del>${selectedPlan.regular_price}</del>
            </p>
          ) : null}
        </div>
      </div>
      <hr />
      <div className="">
        <table className="table w-100 table-borderless table-sm">
          <tbody>
            <tr>
              <td>
                <p>Students</p>
              </td>
              <td className="text-right">
                <h6>{selectedPlan.students}</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Languages</p>
              </td>
              <td className="text-right">
                <h6>Spanish, French, and German</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>Cancel Anytime</p>
              </td>
              <td className="text-right">
                <i className="fa fa-check text-success" aria-hidden="true"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <small className="text-muted d-block text-right">
          <i className="text-success fas fa-badge-dollar"></i>{" "}
          {free_trial?.days || "14"}-day money-back guarantee | Does not
          auto-renew
        </small>
      </div>
    </div>
  );
};

export default OrderSummary;
