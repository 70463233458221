import React from "react";
import { Link } from "react-router-dom";

import nav_side from "../../layouts/nav_side.json";

const DashNavigation: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <main className="dash_navigation first-container last-container">
      <ul className="list-group ">
        {nav_side.map((item) => {
          if (item.teacher_only && !user.is_teacher) return null;
          if (item.student_only && user.is_teacher) return null;
          if (item.admin && !user.admin) return null;

          return (
            <li
              className="list-group-item bg-transparent p-relative"
              key={`dash_navigation_${item.to}`}
            >
              <Link
                className="btn btn-link full_link"
                to={item.to}
                id={`dash_navigation${item.to.split("/").join("_")}`}
              ></Link>
              <span className="text-white">
                <i className={`mr-2 fa-fw ${item.icon}`}></i> {item.txt}
              </span>
            </li>
          );
        })}
      </ul>
    </main>
  );
};

export default DashNavigation;
