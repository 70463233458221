import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($id: ID) {
    user(id: $id) {
      id
      token
      name
      email
      main_contact
      lang_id
      profile_pic
      active
      plan {
        id
        plan_id
        status
        from
        to
        payment_method
        name
      }
    }
    languages {
      id
      name
      active
    }
    getSettings {
      hide_achievements
      block_password_change
      block_lang_selection
      hide_quizzes
      allow_retakes
      angelos_show
      time_zone
      theme
    }
  }
`;

export const GET_NOTI_SETTINGS = gql`
  query getNotiSettings {
    getNotiSettings {
      retakes
      achievements
      new_books
    }
  }
`;

export const SETNOTIFPREFF = gql`
  mutation updateSettings($pref_value: String!, $pref_key: String!) {
    updateNotificationSettings(pref_key: $pref_key, pref_value: $pref_value)
  }
`;

export const GET_INTEGRATIONS = gql`
  query {
    userIntegrations {
      id
      active
      name
      description
      logo
      url
      company
    }
  }
`;
export const SAVE_INTEGRATION = gql`
  mutation saveIntegration($user_token: String!, $app_id: ID!) {
    saveIntegration(app_id: $app_id, user_token: $user_token)
  }
`;
export const UPDATE_INTEGRATION = gql`
  mutation updateIntegration($app_id: ID!) {
    updateStudentsLms(app_id: $app_id)
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($app_id: ID!) {
    deleteIntegration(app_id: $app_id) {
      id
      active
      name
      description
      logo
      url
      company
    }
  }
`;

export const UPDATEPASSWORDS = gql`
  mutation updateStudentsPassword($password: String!) {
    updateStudentsPassword(password: $password)
  }
`;
